<template>
  <!-- 学习详情 -->
  <div class="positionT0L0" id="study_detail">
    <div class="title">
      <p>{{ nickName }} ({{ userName }})</p>
      <p>{{ courseName }}</p>
    </div>
    <!-- 顶部 -->
    <div class="top">
      <div class="top_left"></div>
      <div class="top_rigiht">
        <span>
          <a-input
            v-model="getData.lessonsName"
            placeholder="请输入课节名称"
            style="width: 200px"
            @pressEnter="searchClick"
          >
            <a-icon slot="prefix" type="search" />
          </a-input>
        </span>
        <a-button
          icon="search"
          type="primary"
          @click="searchClick"
          :disabled="isDisableSearch"
        >
          搜索
        </a-button>
      </div>
    </div>
    <!-- 表格 -->
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="false"
      :loading="tableLoading"
    >
      <span slot="learnTime" slot-scope="learnTime, record">
        <span>{{ record.learnTime.toFixed(2) }}</span>
      </span>
    </a-table>
    <!-- 分页功能 -->
    <MyPagination
      :count="count"
      :pageNo="getData.pageNo"
      @showSizeChangeFn="showSizeChangeFn"
      v-show="!tableLoading"
    />
    <!-- 返回顶部 -->
    <a-back-top :target="targetFn" :visibilityHeight="100" />
  </div>
</template>

<script>
import { QueryLearnDataDescResultApi } from "@/request/api/crm";
import MyPagination from "@/components/pagination/MyPagination";
export default {
  components: { MyPagination },
  created() {
    this.nickName = this.$route.query.nickName;
    this.userName = this.$route.query.userName;
    this.courseName = this.$route.query.courseName;
    this.getData.courseNo = this.$route.query.courseNo;
    this.getData.userNo = this.$route.query.userNo;
    this.QueryLearnDataDescResultFn();
  },
  data() {
    return {
      nickName: "",
      userName: "", // 手机号
      courseName: "",
      count: 0,
      getData: {
        pageNo: 1,
        pageSize: 20,
        courseNo: "",
        userNo: "",
        lessonsName: "",
      },
      isDisableSearch: false,
      tableLoading: true,
      columns: [
        {
          title: "课节名称",
          dataIndex: "lessonsName",
          key: "lessonsName",
          width: "10%",
        },
        {
          title: "课节类型",
          dataIndex: "lessonsTypeText",
          key: "lessonsTypeText",
          width: "5%",
        },
        {
          title: "学习时长 (min)",
          dataIndex: "learnTime",
          key: "learnTime",
          width: "6%",
          scopedSlots: { customRender: "learnTime" },
        },
        {
          title: "学习进度",
          dataIndex: "seedingRateFormat",
          key: "seedingRateFormat",
          width: "6%",
        },
        {
          title: "学习次数",
          dataIndex: "learnCount",
          key: "learnCount",
          width: "5%",
        },
      ],
      tableData: [],
    };
  },
  methods: {
    // 搜索的按钮
    searchClick() {
      this.isDisableSearch = true;
      setTimeout(() => {
        this.isDisableSearch = false;
      }, 3000);
      this.getData.pageNo = 1;
      this.QueryLearnDataDescResultFn();
    },
    // 分页功能切换的回调
    showSizeChangeFn(current, pageSize) {
      this.getData.pageNo = current;
      this.getData.pageSize = pageSize;
      this.QueryLearnDataDescResultFn();
    },
    // 获取课节列表
    QueryLearnDataDescResultFn() {
      this.tableLoading = false;
      QueryLearnDataDescResultApi(this.getData).then(({ code, data }) => {
        if (code == 200) {
          this.tableLoading = false;
          data.data.forEach((item, index) => {
            item.key = index;
          });
          this.tableData = data.data;
          this.count = data.count;
        }
      });
    },
    // 回到顶部的函数
    targetFn() {
      return document.querySelector("#study_detail");
    },
  },
};
</script>
 
<style lang="less" scoped>
#study_detail {
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}
.title {
  width: 100%;
  height: 80px;
  padding: 10px;
  font-size: 16px;
  background-color: #ecf8fe;
  p {
    margin-left: 20px;
    line-height: 30px;
    font-size: 14px;
    margin-bottom: 0 !important;
    // &:nth-child(2) {
    //   color: #ccc;
    // }
  }
}
.top {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  .top_rigiht {
    span {
      margin-right: 10px;
      /deep/.ant-input {
        width: 200px;
      }
    }
  }
}
</style>